<template>
  <div class="home_bottom">
    <div class="bg">
      <div class="title">
        <p>Some Promising reasons to choose</p>
        <h3>KiCash</h3>
      </div>
      <ul class="list">
        <li class="left_item">
          <img src="@/assets/images/loanImg/icon_affordable.png" alt="">
          <p class="text1">Multiple lenders provide<br> multiple loan products</p>
          <p class="text2">KiCash provides multiple <br>lenders provide multiple loan products to <br>meet your emergency loan needs.</p>
        </li>
        <li class="right_item">
          <img src="@/assets/images/loanImg/icon_easy.png" alt="">
          <p class="text1">Provide free credit report</p>
          <p class="text2">Instant<br> decisions for Banks, Wallets and E-Commerce,<br>our partnerships with credit<br> information companies Experian and CIBIL, <br>respectively, to offer free credit reports, <br>made your lending more efficient.</p>
        </li>
        <li class="left_item last">
          <img src="@/assets/images/loanImg/icon_we.png" alt="">
          <p class="text1">We are fast</p>
          <p class="text2">When credit report is generated,<br> the loan will be received as soon as 1 hour<br>  after your application</p>
        </li>
        <li class="right_item last">
          <img src="@/assets/images/loanImg/icon_me.png" alt="">
          <p class="text1">Always there for you</p>
          <p class="text2">Lenders on KiCash<br>  are working online 7*24hours.<br>  We are always there for you.</p>
        </li>
        <li class="left_item last">
          <img src="@/assets/images/loanImg/icon-simple.png" alt="">
          <p class="text1">Simple procedure</p>
          <p class="text2">We minimized all the documents to <br>provide you the easiest way to get the<br>loan. With just an application to fill, no <br>collateral and no guarantors.</p>
        </li>
        <li class="right_item last">
          <img src="@/assets/images/loanImg/icon_bank.png" alt="">
          <p class="text1">Credit will increase your loan amount</p>
          <p class="text2">Repayment on time helps to improve credit.<br>The lender will raise your<br> loan limit based on credit.</p>
        </li>
      </ul>
      <div class="phome">
        <img src="@/assets/images/loanImg/phone.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.home_bottom {
  min-width: 1200px;
  .bg {
    position: relative;
    background: url("~@/assets/images/loanImg/bn.png") no-repeat center bottom;
    .phome {
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .title {
    text-align: center;
    margin-bottom: 90px;
    p {
      color: #333333;
      font-size: 16px;
      margin: 0;
    }
    h3 {
      font-size: 68px;
      color: #6650cc;
    }
  }
  .list {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 20px;
    li {
      width: 580px;
      float: left;
      height: 245px;
      margin-bottom: 46px;
      img {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
      }
      .text1 {
        font-size: 22px;
        color: #333333;
        font-weight: 700;
        line-height: 30px;
      }
      .text2 {
        font-size: 14px;
        color: #666666;
        line-height: 19px;
      }
      &.left_item {
        text-align: left;
      }
      &.right_item {
        text-align: right;
      }
      &.last {
        .text1,
        .text2 {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
